import { b } from "@/b";
import { useMutation } from "@apollo/client";
import {
  ModalHeader,
  ModalBody,
  Flex,
  Button,
  Box,
  Heading,
  Input,
} from "@chakra-ui/react";
import { useState, useCallback, useEffect } from "react";
import { DigitInput } from "../DigitInput";

const START_TOTP_MUTATION = b.mutation(
  "StartTOTP",
  { phone: "String!" },
  (b, v) => [
    //
    b.viewer((b) => [b.startTOTPFlow({ phone: v.phone })]),
  ]
);

export const EnterPhoneNumberStep: React.FC<{
  nextStep: () => void;
}> = ({ nextStep }) => {
  const [phone, setPhone] = useState("");
  const [startTOTP, { loading, called, error }] = useMutation(
    START_TOTP_MUTATION.document(),
    {
      onCompleted: () => {
        nextStep();
      },
    }
  );

  useEffect(() => {
    const numericalCharacters = phone.replace(/\D/g, "");
    if (
      numericalCharacters.length === 10 &&
      !phone.includes("+") &&
      !loading &&
      !called
    ) {
      startTOTP({ variables: { phone } });
    }
  }, [loading, called, phone, startTOTP]);

  return (
    <>
      <Heading textAlign="center" fontSize={{ base: "lg", md: "3xl" }} mb="4">
        Enter your phone number
      </Heading>
      <Input
        type="tel"
        w="full"
        h={{ base: "12", md: "24" }}
        fontSize={{
          base: "xl",
          md: "7xl",
        }}
        value={phone}
        onChange={(e) => {
          setPhone(e.target.value);
        }}
        autoComplete="tel"
      />
      {error && (
        <Box color="red.500" textAlign="center">
          {error.message}
        </Box>
      )}
      <Button
        w="full"
        my="4"
        textTransform="uppercase"
        size="lg"
        bg="#8DEBCF"
        isLoading={loading}
        onClick={() => {
          startTOTP({ variables: { phone } });
        }}
      >
        Continue
      </Button>
    </>
  );
};
