import React from "react";
import { EnterPhoneNumberStep } from "./steps/EnterPhoneNumberStep";
import { EnterCodeStep } from "./steps/EnterCodeStep";
import { Box } from "@chakra-ui/react";

export const SignIn: React.FC<{
  onComplete: () => void;
  size?: "sm" | "lg";
}> = ({ onComplete, size = "lg" }) => {
  const [step, setStep] = React.useState<"enter-phone" | "enter-code">(
    "enter-phone"
  );

  let stepNode: React.ReactNode = null;
  if (step === "enter-phone") {
    stepNode = (
      <EnterPhoneNumberStep
        nextStep={() => {
          setStep("enter-code");
        }}
      />
    );
  } else if (step === "enter-code") {
    stepNode = (
      <EnterCodeStep
        onComplete={() => {
          onComplete();
        }}
      />
    );
  }
  return (
    <Box px={{ base: "2", md: "8" }} w="full">
      <Box w="full">{stepNode}</Box>
    </Box>
  );
};
