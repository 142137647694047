import { b } from "@/b";
import { useMutation } from "@apollo/client";
import {
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  Button,
  Heading,
  Text,
  Box,
  Input,
} from "@chakra-ui/react";
import { useState, useCallback, useEffect } from "react";
import { DigitInput } from "../DigitInput";

const VERIFY_TOTP_MUTATION = b.mutation(
  "VerifyTOTP",
  { token: "String!" },
  (b, v) => [
    //
    b.viewer((b) => [
      b.completeTOTPFlow({ token: v.token }, (b) => [
        b.id(),
        b.account((b) => [
          //
          b.id(),
          b.firstName(),
          b.lastName(),
        ]),
        b.employee((b) => [
          //
          b.id(),
        ]),
      ]),
    ]),
  ]
);

export const EnterCodeStep: React.FC<{
  onComplete: () => void;
}> = ({ onComplete }) => {
  const [token, setToken] = useState("");

  const [verifyTOTP, { loading, called, error }] = useMutation(
    VERIFY_TOTP_MUTATION.document(),
    {
      onCompleted: () => {
        onComplete();
      },
    }
  );

  useEffect(() => {
    if (token.length === 6 && !loading && !called) {
      verifyTOTP({ variables: { token } }).catch(() => {});
    }
  }, [verifyTOTP, token, loading, called]);

  return (
    <Flex flexDir="column" w="full">
      <Heading textAlign="center" fontSize={{ base: "lg", md: "3xl" }} mb="4">
        Verify phone number
      </Heading>
      <Text>Enter the code we sent to your phone</Text>
      <Input
        w="full"
        h={{ base: "12", md: "24" }}
        fontSize={{
          base: "xl",
          md: "7xl",
        }}
        value={token}
        onChange={(e) => {
          setToken(e.target.value);
        }}
        autoComplete="one-time-code"
      />
      {error && (
        <Box color="red.500" textAlign="center">
          {error.message}
        </Box>
      )}
      <Button
        w="full"
        my="4"
        textTransform="uppercase"
        size="lg"
        bg="#8DEBCF"
        isLoading={loading}
        onClick={() => {
          verifyTOTP({ variables: { token } }).catch(() => {});
        }}
      >
        Submit
      </Button>
    </Flex>
  );
};
