import { b } from "@/b";
import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SignIn } from "./sign-in/SignIn";

export const SignInModal: React.FC<{
  open: boolean;
  setOpen: (open: boolean) => void;
}> = ({ open, setOpen }) => {
  return (
    <Modal
      isOpen={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <ModalOverlay bg="blackAlpha.700" backdropFilter="blur(4px)" />
      <ModalContent
        maxW="container.md"
        display="flex"
        alignItems="center"
        pt="8"
      >
        {open && <SignIn onComplete={() => setOpen(false)} />}
      </ModalContent>
    </Modal>
  );
};
