import { b } from "@/b";
import { Image, Link } from "@chakra-ui/next-js";
import { Box, Flex, Text, keyframes } from "@chakra-ui/react";
import { FragmentData } from "@gqlb/core";
import { useRouter } from "next/router";
import React, { memo, useEffect, useRef, useState } from "react";
import LOGO from "../img/logo.webp";
import { SignInModal } from "./SignInModal";
import { useApolloClient, useMutation } from "@apollo/client";

export const HEADER_FRAGMENT = b.fragment("Header_Viewer", "Query", (b) => [
  b.viewer((b) => [
    b.id(),
    //
    b.account((b) => [
      //
      b.id(),
    ]),
    b.employee((b) => [
      //
      b.id(),
    ]),
    b.cart((b) => [
      //
      b.totalQuantity(),
    ]),
  ]),
]);

const Header_LogoutMutation = b.mutation("Header_Logout", (b) => [
  b.viewer((b) => [b.logout((b) => [b.id(), b.account((b) => [b.id()])])]),
]);

const HeaderLink: React.FC<
  React.PropsWithChildren<{
    href: string;
    vip?: true;
  }>
> = ({ href, children, vip }) => {
  const router = useRouter();
  let isActive = router.asPath.startsWith(href) || href === router.asPath;
  const vipColor = "#8DEBCF";

  const textColor = vip ? "black" : isActive ? "black" : "#6F7171";
  const border = isActive ? `2px solid ${textColor}` : "2px solid transparent";
  return (
    <Box
      borderBottom={{ base: "none", md: border }}
      py={{
        base: "2",
        md: "0",
      }}
    >
      <Link
        href={href}
        color={textColor}
        fontWeight={vip !== undefined ? "bold" : "regular"}
        transition="all 0.2s ease-in-out"
        bg={vip ? vipColor : "transparent"}
        px={vip ? "2" : "0"}
        rounded={vip ? "md" : "none"}
        roundedBottom={vip && isActive ? "none" : undefined}
        _hover={{
          textDecoration: "none",
        }}
        display="block"
      >
        {children}
      </Link>
    </Box>
  );
};

const DropDownLinks: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [first, ...rest] = React.Children.toArray(children);

  const isMobile = useRef(false);
  useEffect(() => {
    const pointerDown = () => {
      isMobile.current = true;
    };
    window.addEventListener("pointerdown", pointerDown);
    return () => {
      window.removeEventListener("pointerdown", pointerDown);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <Flex
        flexDir="column"
        display={{
          base: "block",
          md: "none",
        }}
      >
        {children}
      </Flex>
      <Box
        position="relative"
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        onClickCapture={(e) => {
          if (isOpen) {
            return;
          }
          if (isMobile.current && !isOpen) {
            e.preventDefault();
            e.stopPropagation();
            setIsOpen(true);
          }
        }}
        display={{
          base: "none",
          md: "block",
        }}
      >
        <Flex flexDir="row" alignItems="center">
          {first}
          <Box
            boxSize="2"
            transform="translateY(-2px) rotate(-45deg)"
            borderLeft="solid #6F7171 2px"
            borderBottom="solid #6F7171 2px"
            ml="2"
          />
        </Flex>
        <Box
          position="absolute"
          top="100%"
          left="0"
          bg="white"
          zIndex="999"
          w="36"
          opacity={isOpen ? 1 : 0}
          transition="all 0.2s ease-in-out"
          pointerEvents={isOpen ? "all" : "none"}
          p="2"
          boxShadow="0px 0px 10px rgba(0,0,0,0.1)"
          roundedBottom="md"
        >
          {React.Children.map(rest, (child) => (
            <Box
              py="1"
              borderBottom="solid #6F7171 1px"
              _last={{
                borderBottom: "none",
              }}
            >
              {child}
            </Box>
          ))}
        </Box>
      </Box>
    </>
  );
};

export const Header: React.FC<{
  data: FragmentData<typeof HEADER_FRAGMENT>;
  banner?: React.ReactNode;
}> = memo(({ data, banner }) => {
  const router = useRouter();

  const isEmployee = data.viewer.employee !== null;

  const [signInOpen, setSignInOpen] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [logoutMutation] = useMutation(Header_LogoutMutation.document());
  const client = useApolloClient();

  const links = (
    <>
      <HeaderLink href="/menu">Menu</HeaderLink>
      <HeaderLink href="/nutrition">Nutrition</HeaderLink>
      <HeaderLink href="/vip" vip>
        VIP
      </HeaderLink>
      <HeaderLink href="/swag">Swag</HeaderLink>
      <DropDownLinks>
        <HeaderLink href="/our-story">Our Story</HeaderLink>
        <HeaderLink href="/meet-the-team">Meet the Team</HeaderLink>
        <HeaderLink href="/our-partners">Our Partners</HeaderLink>
      </DropDownLinks>
      <HeaderLink href="/faq">FAQ</HeaderLink>
      <HeaderLink href="/cart">
        Cart ({data.viewer.cart.totalQuantity})
      </HeaderLink>
      {data.viewer.account === null ? (
        <Box
          onClick={() => setSignInOpen(true)}
          color="#6F7171"
          cursor="pointer"
        >
          Sign In
        </Box>
      ) : (
        <HeaderLink href="/account">Account</HeaderLink>
      )}
      {isEmployee && <Link href="/admin">Admin</Link>}
      {data.viewer.account !== null && (
        <Box
          onClick={async () => {
            await logoutMutation();
            await client.cache.reset();
            router.reload();
          }}
          color="#6F7171"
          cursor="pointer"
          py={{
            base: "2",
            md: "0",
          }}
        >
          Logout
        </Box>
      )}
    </>
  );

  return (
    <>
      {banner && (
        <Box overflow="hidden">
          <Text
            color="#6F7171"
            bg="#8DEBCF"
            fontSize={{
              base: "xs",
              lg: "md",
            }}
            p="4"
            px={{
              base: "8",
              md: "16",
            }}
            textAlign="center"
            css={{
              "@media (max-width: 48em)": {
                br: {
                  display: "none",
                },
              },
            }}
          >
            {banner}
          </Text>
        </Box>
      )}
      <Flex
        as="header"
        pos="sticky"
        top="0"
        w="full"
        maxW="1920px"
        margin="auto"
        px={{
          base: "4",
          lg: "8",
        }}
        py="4"
        alignItems="center"
        gap="6"
        zIndex="999"
        bg="rgba(255,255,255,0.9)"
        backdropFilter="blur(8px)"
      >
        <SignInModal
          open={signInOpen}
          setOpen={(status) => setSignInOpen(status)}
        />

        <Flex
          flexDir="row"
          alignItems="center"
          gap={{
            base: "2",
            md: "4",
          }}
          cursor="pointer"
          onClick={() => router.push("/")}
        >
          <Image
            src={LOGO}
            alt="logo"
            boxSize={{
              base: "10",
              md: "14",
              xl: "20",
            }}
            priority
          />
          <Text
            textTransform="uppercase"
            color="#808080"
            fontSize={{
              base: "2xl",
              lg: "xl",
              xl: "4xl",
            }}
            display={{
              base: "block",
              md: "none",
              lg: "block",
            }}
          >
            Visionary Meals
          </Text>
        </Flex>

        <Flex
          ml="auto"
          display={{
            base: "flex",
            md: "none",
          }}
          flexDir="column"
          onClick={() => setIsHamburgerOpen(!isHamburgerOpen)}
          cursor="pointer"
          rowGap="2"
          w="8"
          h="8"
          alignItems="center"
          justifyContent="center"
          transition="all 0.2s ease-in-out"
          transform={isHamburgerOpen ? "rotate(-90deg)" : "rotate(0deg)"}
        >
          <Box w="8" borderBottom="solid #aaa 3px"></Box>
          <Box w="8" borderBottom="solid #aaa 3px"></Box>
          <Box w="8" borderBottom="solid #aaa 3px"></Box>
        </Flex>
        <Flex
          flexDir="column"
          display={{
            base: "flex",
            md: "none",
          }}
          position="fixed"
          top="72px"
          left="0"
          w="full"
          h="calc(100vh - 72px)"
          bg="white"
          zIndex="998"
          p="4"
          overflowY="auto"
          transform={isHamburgerOpen ? "translateX(0)" : "translateX(-100%)"}
          transition="all 0.2s ease-in-out"
          onClick={() => setIsHamburgerOpen(false)}
        >
          {links}
        </Flex>
        <Flex
          marginLeft="auto"
          flexDir="row"
          gap="4"
          display={{
            base: "none",
            md: "flex",
          }}
          fontSize={{
            base: "md",
            lg: "md",
          }}
        >
          {links}
        </Flex>
      </Flex>
    </>
  );
});

Header.displayName = "Header";
