import { Box, Button, Flex, Grid, Input, Text } from "@chakra-ui/react";
import React from "react";
import LOGO from "../../img/logo.webp";
import { Image } from "@chakra-ui/next-js";
import Link from "next/link";
import { FaInstagram, FaFacebook } from "react-icons/fa";

export const Footer: React.FC = () => {
  return (
    <Box
      as="footer"
      w="full"
      py="12"
      px={{
        base: "4",
        md: "8",
      }}
      maxW="1920"
      margin="auto"
    >
      <Grid
        gridTemplateColumns={{
          base: "auto",
          lg: "auto 1fr auto",
        }}
        gap="8"
        alignItems="center"
        justifyItems="center"
      >
        <Image
          style={{ display: "block" }}
          src={LOGO}
          alt="Logo"
          boxSize={{
            base: "80px",
            md: "120px",
          }}
        />
        <Text
          fontSize={{
            base: "lg",
            md: "2xl",
          }}
          ml={{ base: "0", md: "8" }}
          fontWeight={{
            base: "500",
            lg: "400",
          }}
          justifySelf="flex-start"
          textAlign={{
            base: "center",
            md: "left",
          }}
        >
          Visionary Meals: Envision the Best You. One Meal at a Time.
        </Text>
        <Box
          as={Link}
          href="/vip"
          rounded="full"
          bg="#8DEBCF"
          p="4"
          fontWeight="400"
          display={{ base: "none", md: "block" }}
          textAlign="center"
          w="36"
        >
          Become a VIP
        </Box>
      </Grid>
      <Grid
        gridTemplateColumns={{
          base: "auto",
          md: "auto auto",
          lg: "auto auto 1fr 1fr",
        }}
        gridAutoRows="auto"
        mt="8"
        columnGap="12"
        rowGap="8"
      >
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          alignItems={{
            base: "center",
            md: "flex-start",
          }}
        >
          <Text
            fontWeight="semibold"
            fontSize={{
              base: "xl",
              md: "md",
            }}
          >
            Connect With Us
          </Text>
          <Flex flexDir={"row"} columnGap="3">
            <Link
              href="https://www.instagram.com/visionary.meals"
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram size={24} />
            </Link>
            <Link
              href="https://www.facebook.com/VisionaryMeals"
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook size={24} />
            </Link>
          </Flex>
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          alignItems={{
            base: "center",
            md: "flex-start",
          }}
        >
          <Text
            fontWeight="semibold"
            textAlign="center"
            fontSize={{
              base: "xl",
              md: "md",
            }}
          >
            Contact Us
          </Text>
          <Text as="a" mt="4" href="tel:7406395412">
            (740) 639-5412
          </Text>
          <Text as="a" href="mailto:info@visionarymeals.com">
            info@visionarymeals.com
          </Text>
          <Text>2567 North St, Granville, OH 43023</Text>
        </Flex>
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          alignItems={{
            base: "center",
            md: "flex-start",
          }}
        >
          <Text
            fontWeight="semibold"
            textAlign={{
              base: "center",
              md: "left",
            }}
            fontSize="md"
          >
            Want to save 25% on your meals?
          </Text>
          <Text
            lineHeight={{ base: "4", md: "6" }}
            mt={{ base: "2", md: "4" }}
            fontSize={{
              base: "xs",
              md: "sm",
            }}
            textAlign={{
              base: "center",
              md: "left",
            }}
          >
            Become a Visionary VIP today!
          </Text>
          <Button
            as="a"
            rounded="full"
            background="white"
            border="1px solid black"
            href="/vip"
            mt="4"
          >
            Become a VIP
          </Button>
        </Flex>
        <Flex
          as="form"
          onClick={(e) => {
            e.preventDefault();
          }}
          flexDir="column"
          justifyContent="flex-start"
          alignItems={{
            base: "center",
            md: "flex-start",
          }}
        >
          <Text
            fontWeight="semibold"
            textAlign={{
              base: "center",
              md: "left",
            }}
            fontSize="md"
          >
            Sign Up for Our Email Newsletter
          </Text>
          <Text
            lineHeight={{ base: "4", md: "6" }}
            mt={{ base: "2", md: "4" }}
            fontSize={{
              base: "xs",
              md: "sm",
            }}
          >
            Get exclusive deals, nutrition tips, gym motivation and more!
          </Text>
          <Input my="4" placeholder="Enter your email address" rounded="full" />
          <Button rounded="full" background="white" border="1px solid black">
            Submit
          </Button>
        </Flex>
      </Grid>
      <Box w="full" border="solid 1px #6F7171" my="8" />
      <Flex
        flexDir="row"
        alignItems="center"
        justifyContent="space-between"
        gap="4"
      >
        <Text fontSize="sm" textAlign="center" color="#6F7171">
          © 2024 Visionary Meals. All rights reserved.
        </Text>
      </Flex>
    </Box>
  );
};
