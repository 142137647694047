import { Box } from "@chakra-ui/react";
import React from "react";

export const Section: React.FC<
  React.PropsWithChildren<{
    padded?: boolean;
    noTopPadding?: boolean;
    background?: string;
  }>
> = ({ padded, children, noTopPadding, background }) => {
  return (
    <Box
      px={
        padded
          ? {
              base: "4",
              lg: "8",
            }
          : "0"
      }
      w="full"
      maxW="1920px"
      margin="auto"
      pt={
        noTopPadding
          ? undefined
          : {
              base: "2",
              lg: "6",
            }
      }
      pb={{
        base: "2",
        lg: "12",
      }}
      bg={background}
    >
      {children}
    </Box>
  );
};
