import React from "react";
import { HEADER_FRAGMENT, Header } from "./Header";
import { Box, ResponsiveObject } from "@chakra-ui/react";
import { FragmentData } from "@gqlb/core";
import { Footer } from "./layout/Footer";
import Link from "next/link";

const BANNER = (
  <>
    <b>LIMITED TIME</b> Join our VIP program for just $0.99 (SAVE $79). Use code{" "}
    <b>VIP99</b> or click{" "}
    <b>
      <em>
        <Link href="/menu?coupon=VIP99">here</Link>
      </em>
    </b>{" "}
    to take advantage of this offer!
  </>
);
// const BANNER = undefined;

export const PageLayout: React.FC<
  React.PropsWithChildren<{
    data: FragmentData<typeof HEADER_FRAGMENT>;
    noPadding?: boolean;
  }>
> = ({ children, data, noPadding }) => {
  let padding: ResponsiveObject<string> | undefined;
  if (noPadding) {
    padding = undefined;
  } else {
    padding = {
      base: "4",
      lg: "12",
    };
  }
  return (
    <>
      <Header data={data} banner={BANNER} />
      <Box as="main" pt={padding} minH="80vh">
        {children}
      </Box>
      <Footer />
    </>
  );
};
